<template>
	<div class="">
		<a-spin :spinning="loading">
			<div class="flex space alcenter">
				<div class="ft20 cl-main ftw600">{{getTitle}}</div>
				<a-button @click="$router.go(-1)">返回</a-button>
			</div>
			
			<div class="mt20 bg-w pd40">
				<div style="width: 1000px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item required label="项目全称" help="30字以内">
							<a-input v-model="form.full_name" :maxLength="30" placeholder="输入项目全称" style="width: 480px;"></a-input>
						</a-form-model-item>
						
						<a-form-model-item required label="项目简称" help="10字以内">
							<a-input v-model="form.abbreviation"  :maxLength="10" placeholder="输入项目简称" style="width: 300px;"></a-input>
						</a-form-model-item>
						
						<a-form-model-item required label="项目分类" >
							<a-select v-model="form.project_type_id" style="width:200px" >
							    <a-select-option :value="0">
							        选择分类
							    </a-select-option>
								<a-select-option :value="item.project_type_id" v-for="(item,index) in type_list">
								    {{item.name}}
								</a-select-option>
							</a-select>
						</a-form-model-item>
						
						<a-form-model-item required label="缩略图" >
							<div class="ft12 ftw400 cl-notice">建议上传300x240px</div>
							<div class="mt10 ml20">
								<upload-img v-model="form.cover_img"></upload-img>
							</div>
						</a-form-model-item>
						
						<a-form-model-item required label="门店原价">
							<a-input-number v-model="form.market_price"  :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						
						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
						      <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
						        确定保存
						      </a-button>
						</a-form-model-item>
					</a-form-model>	
					
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import uploadImg from '../../components/upload/upload.vue';
	import uploadList from '../../components/upload/list.vue';
	export default{
		components:{
			uploadImg,
			uploadList,
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				labelCol: { span: 4 },
			    wrapperCol: { span: 20 },
				project_id:0,
				type_list:[],
				form: {
					full_name:'',
					abbreviation:'',
					project_type_id:0,
					cover_img:'',
					market_price:'',
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.project_id=0;
			} else {
				this.project_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.project_id==0){
					return '添加项目';
				}else{
					return '编辑项目';
				}
			}
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showProject',{
					project_id:this.project_id
				}).then(res=>{
					if(res.detail){
						this.form=res.detail;
					}
					this.type_list=res.type_list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveProject',{
					project_id:this.project_id,
					full_name: this.form.full_name,
					abbreviation:this.form.abbreviation,
					project_type_id: this.form.project_type_id,
					cover_img: this.form.cover_img,
					market_price: this.form.market_price,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.$router.go(-1);
					});
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
		}
	}
</script>

<style>
	
</style>